<template>
    <div>
        <div class="row">
            <div class="col-md-12">
                <KTCard>
                    <template v-slot:title>
                        {{operationTitle}}
                        <p><small>Buy New Business here.</small></p>
                    </template>
                    <template v-slot:toolbar>
                        <b-input-group-append>
                            <router-link to="/bounce/events/create">
                                <b-button class="btn btn-primary font-weight-bolder"
                                          v-if="globalPropertyId > 0 && $global.hasPermission('bounceeventsstore')"
                                          v-b-tooltip.hover :title="`Add New`"
                                          variant="success"><i class="fa fa-plus fa-sm"></i> Add New
                                </b-button>
                            </router-link>
                            <b-button
                                :class="checkFilterCount ? `btn btn-danger text-white font-weight-bolder ml-2` : 'btn btn-light-primary font-weight-bolder ml-2'"
                                v-b-toggle.sidebar-backdrop-bounce-event>
                                <i class="fa fa-filter fa-sm"></i> Filter

                            </b-button>
                        </b-input-group-append>
                    </template>

                    <template v-slot:body>
                        <v-card>
                            <div class="bounceevents-table"
                                 v-if="$global.hasPermission('bounceeventsview')">

                                <div class="w-100 mb-6">
                                    <div class="row">
                                        <div class="col-sm-12 col-md-5">
                                            <div class="dataTables_length"><label>Show
                                                <b-form-select :options="[10, 50, 100]"
                                                               class="datatable-select"
                                                               size="sm"
                                                               v-model="pagination.totalPerPage"></b-form-select>
                                                entries</label></div>

                                        </div>
                                        <div class="col-sm-12 col-md-2">
                                            <p v-if="global.pendingRequests > 0">
                                                <i class="fa fa-spinner"></i>
                                            </p>
                                        </div>
                                        <div class="col-sm-12 col-md-5">
                                            <div class="dataTables_filter" id="kt_datatable_filter"><label>Search:
                                                <b-form-input class="form-control form-control-sm ml-2 w-25"
                                                              label="Search"
                                                              type="search"
                                                              v-model="search"
                                                              v-on:keyup.enter="handleSearch"></b-form-input>
                                            </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <v-data-table
                                    :headers="columns"
                                    :items="dataSource"
                                    :items-per-page="parseInt(pagination.totalPerPage)"
                                    :loading="loading"
                                    class="table table-head-custom table-vertical-center table-responsive"
                                    hide-default-footer
                                    ref="table"
                                    responsive
                                >
                                    <template v-slot:item.id="record">
                                        {{ dataSource.length - record.index }}
                                    </template>
                                    <template v-slot:item.date="{item}">
                                        {{ $global.dateFormat(item.event_from_date) }} <br/>
                                        {{ $global.dateFormat(item.event_to_date) }}
                                    </template>
                                    <template v-slot:item.client_budget="{item}">
                                        {{ $global.numberToLocationString(item.client_budget) }}
                                    </template>
                                    <template v-slot:item.status="{item}">
                                        <span
                                            class="label label-lg label-inline label-light-danger"
                                            v-if="!item.status">
                                           Pending
                                        </span>
                                        <span class="label label-lg label-inline label-light-success" v-else>
                                            Shared
                                        </span>
                                    </template>
                                    <template class="action-column" v-slot:item.action="{item}">
                                        <b-dropdown no-caret size="sm" toggle-class="text-decoration-none"
                                                    v-if="$global.hasPermission('sharebounceeventsstore')"
                                                    variant="link">
                                            <template #button-content>
                                                <p class="btn btn-icon btn-light btn-hover-primary btn-sm">
                                                     <span
                                                         class="svg-icon svg-icon-md svg-icon-primary m-0">
                                                      <!--begin::Svg Icon-->
                                                      <inline-svg
                                                          class="action-edit-button"
                                                          src="/media/svg/icons/General/Settings-1.svg"
                                                      ></inline-svg>
                                                         <!--end::Svg Icon-->
                                                    </span>
                                                </p>
                                            </template>
                                            <b-dropdown-item @click="openShareBounceEvent(item)"
                                                             href="#"
                                                             v-b-modal.share-bounce-event
                                                             v-id="$global.hasPermission('sharebounceeventsstore')">
                                                            <span class="pt-1 pb-1"
                                                                  size="sm">
                                                                <i class="fa fa-share-alt mr-2"></i> Share
                                                            </span>
                                            </b-dropdown-item>
                                        </b-dropdown>
                                        <router-link :to="`/bounce/events/edit/${item.id}`">
                                            <a size="sm" class="btn btn-icon btn-light btn-hover-primary btn-sm ml-2"
                                               v-show="$global.hasPermission('bounceeventsupdate')">
                                                <span
                                                    class="svg-icon svg-icon-md svg-icon-primary">
                                                  <!--begin::Svg Icon-->
                                                  <inline-svg
                                                      src="/media/svg/icons/Communication/Write.svg"
                                                      class="action-edit-button"
                                                  ></inline-svg>
                                                    <!--end::Svg Icon-->
                                                </span>
                                            </a>
                                        </router-link>
                                        <a @click="handleDeleteOperation(item.id)"
                                           class="ml-2 btn btn-icon btn-light btn-hover-primary btn-sm"
                                           size="sm"
                                           v-if="$global.hasPermission('bounceeventsdestroy') && !item.status">
                                            <span
                                                class="svg-icon svg-icon-md svg-icon-primary">
                                              <!--begin::Svg Icon-->
                                              <inline-svg
                                                  class="action-delete-button" src="/media/svg/icons/General/Trash.svg"
                                              ></inline-svg>
                                                <!--end::Svg Icon-->
                                            </span>
                                        </a>
                                    </template>
                                </v-data-table>
                                <div class="row">
                                    <div class="pagination-box">
                                        <b-pagination
                                            :per-page="pagination.totalPerPage"
                                            :total-rows="pagination.total"
                                            size="lg"
                                            v-model="pagination.current"
                                        ></b-pagination>
                                    </div>
                                </div><!-- /.pull-right -->
                            </div>
                        </v-card>
                    </template>
                </KTCard>

                <template>
                    <div>
                        <b-sidebar
                            :backdrop-variant="`transparent`"
                            backdrop
                            id="sidebar-backdrop-bounce-event"
                            shadow
                            title="Filters"
                            right
                        >
                            <template #footer="{ hide }">
                                <div
                                    class="d-flex justify-center cursor-pointer w-full border-top align-items-center px-3 py-4">
                                    <b-button @click="hide();handleResetFilterClick()"
                                              class="btn btn-light-primary font-weight-bolder ml-2">
                                        <i class="fa fa-broom fa-sm"></i> Clear All
                                    </b-button>
                                </div>
                            </template>
                            <div class="px-3 py-2">
                                <b-col sm="12">
                                    <b-form-group
                                        label="Start Date"
                                        label-for="from_date">
                                        <b-form-datepicker
                                            :date-format-options="{ year: 'numeric',month: 'short', day: 'numeric' }"
                                            close-button
                                            reset-button
                                            today-button
                                            v-model="filters.from_date"
                                        >
                                        </b-form-datepicker>
                                    </b-form-group>
                                </b-col>
                                <b-col sm="12">
                                    <b-form-group
                                        label="End Date"
                                        label-for="to_date">
                                        <b-form-datepicker
                                            :date-disabled-fn="dateDisabled"
                                            :date-format-options="{ year: 'numeric',month: 'short', day: 'numeric' }"
                                            :disabled="!filters.from_date"
                                            close-button
                                            reset-button
                                            today-button
                                        >
                                        </b-form-datepicker>
                                    </b-form-group>
                                </b-col>
                            </div>
                        </b-sidebar>
                    </div>
                </template>

                <b-modal hide-footer id="share-bounce-event" size="lg" title="Marketplace Detail">
                    <form @submit.prevent="handleSubmitShareBounceEventOperation" autocomplete="off">
                        <b-row>
                            <b-col class="p-0" cols="12" lg="12" md="12" sm="12">
                                <b-row>
                                    <b-col class="mb-2" sm="12">
                                        <b-form-checkbox
                                            @change="handleSelectAllUsers"
                                            v-model="selectAllUsers"
                                        >
                                            Select All
                                        </b-form-checkbox>
                                    </b-col>
                                    <b-col sm="6">
                                        <b-form-group
                                            :invalid-feedback="formErrors.first('city_id')"
                                            label="City *"
                                            label-for="title"
                                        >
                                            <treeselect
                                                :class="[{'invalid is-invalid': (formErrors.has('city_id'))}]"
                                                :multiple="true"
                                                :options="dropdowns.cities"
                                                id="State"
                                                label="State_id"
                                                v-model="shareBounceEvent.city_id"
                                            />
                                        </b-form-group>
                                    </b-col>
                                    <b-col sm="6">
                                        <b-form-group
                                            :invalid-feedback="formErrors.first('user_id')"
                                            label="Banquet *"
                                            label-for="Banquet"
                                        >
                                            <treeselect
                                                :class="[{'invalid is-invalid': (formErrors.has('user_id'))}]"
                                                :multiple="true"
                                                :options="dropdowns.companyProfiles"
                                                id="user_id"
                                                label="user_id"
                                                v-model="shareBounceEvent.company_profile_id"
                                            />
                                        </b-form-group>
                                    </b-col><!--/b-col-->
                                    <b-col sm="6">
                                        <b-form-group
                                            :invalid-feedback="formErrors.first('rate')"
                                            label="Rate *"
                                            label-for="rate"
                                        >
                                            <b-form-input
                                                :state="((formErrors.has('rate') ? false : null))"
                                                @focus="$event.target.select()"
                                                id="rate"
                                                type="text"
                                                v-model="shareBounceEvent.rate"
                                                v-numericOnly.keyup
                                            ></b-form-input>
                                        </b-form-group>
                                    </b-col><!--/b-col-->
                                </b-row>
                                <b-row class="operation-footer">
                                    <b-col sm="12">
                                        <b-button
                                            :disabled="global.pendingRequests > 0"
                                            size="sm"
                                            type="submit"
                                            variant="primary"
                                        >
                                            <i class="fa fa-spin fa-spinner"
                                               v-show="global.pendingRequests > 0"></i>
                                            <i class="fa fa-save fa-sm"></i>
                                            Save
                                        </b-button>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                    </form>
                </b-modal>
            </div>

        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex'
    import KTCard from "@/view/content/Card.vue";
    import ListingMixin from "@/assets/js/components/ListingMixin";
    import ModuleMixin from './module.mixin'

    const DEFAULT_FILTER_STATE = {
        from_date: null,
        to_date: null,
    };

    export default {
        mixins: [ListingMixin, ModuleMixin],
        data() {
            return {}
        },
        components: {
            KTCard,
        },
        methods: {
            handleResetFilterClick() {
                this.filters = {...DEFAULT_FILTER_STATE}
                this.isFilterApplied = 'reset'
                this.loadList(this.listQueryParams)
            }
        },
        computed: {
            ...mapState([
                'global',
            ]),
        },
    }
</script>
